<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <actor-role-create
      :ams_template="ams_template"
      :reroute="reroute"
    ></actor-role-create>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ActorRoleCreate from './ActorRoleCreate.vue'

export default {
  components: {
    VuexBreadcrumb,
    ActorRoleCreate
  },
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    }
  }
}
</script>